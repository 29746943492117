<template>
    <v-card
        :loading="loading"
        class="teacher-personal-data"
    >
        <div
            v-if="!readOnly"
            class="w-actions-menu"
        >
            <w-actions-menu
                :items="actionsMenuItems"
            />
        </div>
        <v-card-title class="d-flex justify-center flex-column py-10">
            <member-avatar-form
                :member="item"
                member-type="teacher"
                :read-only="readOnly"
            />
        </v-card-title>
        <v-card-text>
            <h2 class="text-md text-center font-weight-semibold mb-2">
                {{ item | memberFullName }}
            </h2>
            <v-divider></v-divider>
            <v-list v-if="isItem" dense>
                <v-list-item
                    v-for="property in itemProperties"
                    :key="property.label"
                    class="px-0 mb-n2"
                >
                    <span class="font-weight-medium me-2">{{ property.label }}:</span>
                    <span class="text--secondary">{{ property.value }}</span>
                </v-list-item>
            </v-list>
        </v-card-text>
        <w-aside-drawer
            v-model="isForm"
            :title="$trans('Update')"
        >
            <div class="pa-4">
                <teacher-form
                    :item="item"
                    @updated="onSubmitForm"
                />
            </div>
        </w-aside-drawer>
    </v-card>
</template>

<script>
import _ from 'lodash'
import hasMemberFullNameFilterMixin from '@/components/Member/mixins/hasMemberFullNameFilterMixin'
import moment from 'moment'
import hasLocalePropertyMixin from '@apps/system/mixins/hasLocalePropertyMixin'
import { deleteTeacher, getTeacher } from '@apps/school/api/teachers'
import TeacherForm from '@apps/school/components/Teacher/TeacherForm'
import ConfirmationSets from '@/utils/ConfirmationSets'
import { memberFullName } from '@/components/Member/utils'
import MemberAvatarForm from '@apps/auth/components/MemberAvatarForm'

export default {
    name: 'TeacherPersonalData',
    components: { MemberAvatarForm, TeacherForm },
    mixins: [
        hasMemberFullNameFilterMixin,
        hasLocalePropertyMixin
    ],
    props: {
        uuid: {
            type: String,
            required: true
        },
        readOnly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            item: {},
            loading: true,
            isForm: false
        }
    },
    computed: {
        isItem() {
            return !_.isEmpty(this.item)
        },
        itemProperties() {
            return [
                {
                    label: 'Email',
                    value: this.item.email
                },
                {
                    label: this.$trans('Phone'),
                    value: this.item.phone
                },
                {
                    label: this.$trans('Birthday'),
                    value: this.birthdayComputed.date
                },
                {
                    label: this.$trans('Age'),
                    value: this.birthdayComputed.age
                }
            ]
        },
        birthdayComputed() {
            moment.updateLocale(this.locale, {})

            const date = moment(this.item.birthday)

            return {
                date: date.format('DD MMMM YYYY'),
                age: date.toNow(true)
            }
        },
        actionsMenuItems() {
            return [
                {
                    text: this.$trans('Edit'),
                    icon: 'EDIT',
                    color: 'success',
                    action: 'onEditItem',
                    on: {
                        click: this.onEditItem
                    }
                },
                {
                    text: this.$trans('Delete'),
                    icon: 'DELETE',
                    color: 'error',
                    action: 'onDeleteItem',
                    on: {
                        click: this.onDeleteItem
                    }
                }
            ]
        }
    },
    mounted() {
        this.fetchItem(true)
    },
    methods: {
        fetchItem(emitLoadedEvent) {
            getTeacher(this.uuid)
                .then(response => {
                    this.item = response.data.data
                    this.loading = false

                    if (emitLoadedEvent) {
                        this.$emit('loaded', this.item)
                    }
                })
                .catch(() => {
                    this.loading = false
                })
        },
        onEditItem() {
            this.isForm = true
        },
        onSubmitForm() {
            this.isForm = false
            this.fetchItem(false)
        },
        async onDeleteItem() {
            const isConfirm = await this.$confirm(ConfirmationSets.deleteAndCannotUndoneSet({
                title: `${this.$trans('Delete')} ${memberFullName(this.item)}`
            }))

            if (!isConfirm) {
                return
            }

            try {
                await deleteTeacher(this.item.uuid)
                this.$emit('deleted')
            } catch (error) {}
        }
    }
}
</script>

<style lang=scss>
    .teacher-personal-data {
        .w-actions-menu {
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }

</style>
