<template>
    <w-simple-form
        :key="keyComponent"
        :disabled="isFormDisabled"
        :item="item"
        :fields="teacherFormFields"
        :errors="responseErrors"
        @save="onSave"
        @update="onUpdate"
    />
</template>

<script>
import Inputmask from 'inputmask'
import { emailValidator, regexValidator, required } from '@/utils/validation'
import { storeTeacher, updateTeacher } from '@apps/school/api/teachers'
import hasDepartmentsPropertyMixin from '@apps/school/mixins/hasDepartmentsPropertyMixin'

export default {
    name: 'TeacherForm',
    mixins: [ hasDepartmentsPropertyMixin ],
    props: {
        item: {
            type: Object,
            default: function () {
                return {}
            }
        },
        departmentUuid: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            keyComponent: 0,
            isFormDisabled: false,
            responseErrors: {},
            fieldBaseBindings: {
                outlined: true,
                dense: true,
                required: true
            }
        }
    },
    computed: {
        computedItem() {
            const item = this.item

            if (item.departments) {
                item.departments = item.departments.map(o => {
                    return o.uuid
                })
            }

            return item
        },
        teacherFormFields() {
            const fields = [
                {
                    name: 'last_name',
                    type: 'v-text-field',
                    props: {
                        label: this.$trans('Last name'),
                        ...this.fieldBaseBindings,
                        rules: [
                            required
                        ]
                    }
                },
                {
                    name: 'first_name',
                    type: 'v-text-field',
                    props: {
                        label: this.$trans('First name'),
                        ...this.fieldBaseBindings,
                        rules: [
                            required
                        ]
                    }
                },
                {
                    name: 'middle_name',
                    type: 'v-text-field',
                    props: {
                        label: this.$trans('Middle name'),
                        ...this.fieldBaseBindings,
                        rules: [
                            required
                        ]
                    }
                },
                {
                    name: 'gender',
                    type: 'w-gender-input',
                    props: {
                        ...this.fieldBaseBindings,
                        required: true,
                        locale: this.$store.getters.locale
                    }
                },
                {
                    name: 'birthday',
                    type: 'w-birthday-input',
                    props: {
                        ...this.fieldBaseBindings,
                        locale: this.$store.getters.locale,
                        firstDayOfWeek: this.$store.getters.firstDayOfWeek,
                        required: true
                    }
                },
                {
                    name: 'email',
                    type: 'v-text-field',
                    props: {
                        label: 'E-mail',
                        ...this.fieldBaseBindings,
                        rules: [
                            required,
                            emailValidator
                        ]
                    }
                },
                {
                    name: 'phone',
                    type: 'v-text-field',
                    on: {
                        focus: this.initPhoneMask
                    },
                    props: {
                        id: 'teacher-phone-input',
                        label: this.$trans('Phone'),
                        ...this.fieldBaseBindings,
                        rules: [
                            required,
                            v => regexValidator(v, '^\\+38 \\(0([0-9]{2})\\) ([0-9]{3})-([0-9]{2})-([0-9]{2})$')
                        ]
                    }
                }
            ]

            if (Object.keys(this.item).length === 0 && !this.departmentUuid) {
                fields.push({
                    name: 'department_uuids',
                    type: 'v-select',
                    props: {
                        label: this.$trans('Department'),
                        ...this.fieldBaseBindings,
                        rules: [
                            required
                        ],
                        items: this.departments,
                        itemText: 'title',
                        itemValue: 'uuid'
                    }
                })
            }

            return fields
        }
    },
    watch: {
        item() {
            this.keyComponent++
        }
    },
    methods: {
        initPhoneMask() {
            const selector = document.getElementById('teacher-phone-input')
            const im = new Inputmask('+38 (099) 999-99-99')

            if (selector) {
                im.mask(selector)
            }
        },
        async onSave(data) {
            this.responseErrors = {}
            this.isFormDisabled = true

            try {
                if(this.departmentUuid) {
                    data.department_uuids = this.departmentUuid
                }

                const response = await storeTeacher(data)
                this.isFormDisabled = false
                this.$emit('save', response.data)
                this.$emit('submit', response.data)
            } catch (e) {
                this.isFormDisabled = false

                if (e.response.data.errors) {
                    this.responseErrors = e.response.data.errors
                }
            }
        },
        async onUpdate(data, item) {
            this.isFormDisabled = true
            try {
                const response = await updateTeacher(item.uuid, data)
                this.isFormDisabled = false
                this.$emit('updated', response.data)
                this.$emit('submit', response.data)
            } catch (error) {
                this.responseErrors = error.response.data.errors || {}
                this.isFormDisabled = false
            }
        }
    }
}
</script>

<style lang=scss>

</style>
